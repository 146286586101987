@charset "UTF-8";

.NewsList {
  display: table;
  border-spacing: 0px $space-unit/2;
  font-size: 14px;
  border-collapse: separate;
  @include mq-sp{
    font-size: 12px;
  }
  &__items {
    display: table-row-group;
  }
  &__item {
    display: table-row;
  }
  &__head {
    display: table-cell;
    padding-right: $column-space;
    @include mq-sp{
      padding-right: $space-unit;
    }
  }
  &__body {
    display: table-cell;
    flex: 1 1 auto;
  }
}
