@charset "UTF-8";

.LegalNotice {
  width: 80%;
  margin: 0 auto;
  font-size: 14px;
  &__menu {
    display: flex;
    flex-direction: column;
    margin-bottom: $space-unit;
  }
  @include mq-sp{
    font-size: 12px;
  }
}


.table {
  border-collapse: separate;
  border-spacing: 4px;
  margin-bottom: $space-unit;
&__th {
  text-align: left;
  width: 30%;
  padding: 10px;
  }
&__td {
  width: 70%;
  padding: 10px;
  }
}
