@charset "UTF-8";

.sectionLabel {
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  padding-top: $space-unit;
  padding-bottom: $space-unit*2;
  color: $color-main-text;
  text-transform: uppercase;
  font-size: 22px;
  line-height: 1;

  @include menu-font;

  &::before,
  &::after {
    content: '';
    display: block;
    flex:1 0 10%;
    height: 0;
    border-bottom: 1px solid $color-main-text;
  }
  &::before {
    margin-right: 22px;
  }
  &::after {
    margin-left: 22px;
  }
}
// ロゴ
.logo {
    display: inline-block;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 26px;
    @include title-font;
}
.label {
  display: inline-block;
  padding: 5px;
  font-size: 16px;
  @include title-font;
}
