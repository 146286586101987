@charset "UTF-8";

.BrandList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0;
  &__sub {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: $space-unit/2;
    @include mq-sp {
      flex-wrap: wrap;
      justify-content: center;
    }
    }
  &__item {
      padding-right: $space-unit;
      margin: 5px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
