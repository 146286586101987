@charset "UTF-8";

.Access {
  display: flex;
  margin: 0 auto;
  width: 70%;
  @include mq-sp {
    flex-wrap: wrap;
    flex-basis: 100%;
    align-self: auto;
    text-align: center;
  }
  &__map {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: auto;
    &__iframe {
      width: 100%;
      height: auto;
    }
  }
  &__photo {
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    margin: 0;
    overflow: hidden;
  }
  &__main {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
      flex-wrap: wrap;
      flex-basis: 100%;
    }
  }
  &__address {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
    }
  }
  &__brand {
    display: flex;
    flex-direction: column;
    text-align: center;
    @include menu-font;
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
    }
  }
  &__calender {
    text-align: center;
    font-size: 14px;
    &__iframe {
      text-align: center;
    }
    @include mq-sp {
      font-size: 12px;
    }
  }
}
