@charset "UTF-8";

.OverlayPanel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10% 20%;
  color: $color-white;
  width: 100%;
  &__head {
    margin: 0 10%;
    padding: 24px 36px;
    border: 2px solid $color-white;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .1em;
    font-size: 24px;
    @include title-font;
    }
  &__body {
    margin: 16px auto;
    font-size: 14px;
    @include mq-sp {
      display: none;
    }
   }
}
