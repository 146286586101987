@charset "UTF-8";

.Goods {
  &__text {
    margin-bottom: $space-unit*2;
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
    }
  }
  &__image {
    margin-bottom: $space-unit*2;
    width: 100%;
    height: auto;
  }
  &__list {
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
    }
  }
}
