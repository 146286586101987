@charset "UTF-8";

.SNS {
  display: flex;
  align-items: center;
  font-size: 22px;
  @include mq-sp{
    font-size: 16px;
  }
  &__text {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    overflow: hidden;
  }
  &__item {
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
  }
}
