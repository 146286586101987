@charset "UTF-8";

//sizes
$base-content-width: 1180px;
$space-unit: 20px;
$sp-space-unit: 15px;
$column-space: $space-unit*2;

$color-base: #eae1cf;
$color-main-text: #24140e;
$color-link: #504946;
$color-border: #6c2735;
$color-black: #24140e;
$color-white: #fff;
$color-reverse-line: #fff;
$color-reverse-fill: #252525;

//paths
$image-path: "../images/";

//fonts
$font-sub: 'Oxygen';
//break points
$sp-max-width: 640px;
$pc-min-width: 641px;
$sp-menu-width: 200px;
