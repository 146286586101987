@charset "UTF-8";

.Section {
  margin-bottom: $space-unit*2;
  &__item {
    margin-bottom: $space-unit*2;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
