@charset "UTF-8";

.IndexPage {
  &__blog,
  &__online,
  &__sale {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }

  &__blog {
    background-image: url('#{$image-path}top/content_blog.jpg');
  }
  &__online {
    background-image: url('#{$image-path}top/content_online.jpg');
  }
  &__sale {
    background-image: url('#{$image-path}top/content_sale.jpg');
  }
}
