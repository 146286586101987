@charset "UTF-8";

.PickUp {
  display: flex;
  align-items: center;
  width: 100% auto;
  &__main {
    display: flex;
    flex: 1 1 50%;
    flex-direction: column;
    justify-content: space-around;
    align-self: stretch;
    padding: 20px 0;
    margin: $space-unit/2;
  }
  &__sub {
    flex: 1 1 50%;
  }
  &__thumb {
    width: 100%;
    height: auto;
  }
  &__head {
    font-weight: bold;
    font-size: 18px;
  }
  &__body {
    margin-top: 10px;
    font-size: 14px;
  }
}
