@charset "UTF-8";
.instagram {
  width: 90% auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.instagram li {
margin: 5px;
  float: left;
  width: 100% auto;
  height: 100% auto;
  overflow: hidden;
  position: relative;
}
p.ins_like {
position: absolute;
width: 100%;
height: 40px;
bottom: 0;
left: 0;
background: rgba(255,255,255,0.70);
padding: 10px 20px 10px 0;
box-sizing: border-box;
text-align: right;
}
.instagram li img {
max-width: 100%;
}
.fa-heart {
margin-right: 10px;
color: #EA4E50;
}
