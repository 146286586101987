@charset "UTF-8";

//google fonts
@import url('https://fonts.googleapis.com/css?family=Oxygen:400,700');

//==============================
//Base
//==============================
@import "./base/variables";
@import "./base/reset";
@import "./base/mixins";
//==============================
//Components
//==============================

//Parts
//------------------------------
//レイアウト
@import "./components/parts/layouts";
//ラベル、見出し
@import "./components/parts/labels";
//mini calendar
@import "./components/parts/calendar";
//ボタン
@import "./components/parts/buttons";
//メールフォーム
@import "./components/parts/mailform";
//インスタグラム
@import "./components/parts/insta";

//Common
//------------------------------
@import "./components/common/header";
@import "./components/common/menu";
@import "./components/common/section";
@import "./components/common/footer";
@import "./components/common/sns";
@import "./components/common/documents";
//Others
//------------------------------
@import "./components/pickUp";
@import "./components/imageSlide";
@import "./components/brandlist";
@import "./components/overlayPanel";
@import "./components/access";
@import "./components/newsList";
@import "./components/LegalNotice";
@import "./components/entryPanel";

//pages
@import "./pages/index";
@import "./pages/goods";

//font-awesome
@import "./base/lib/fa/font-awesome";
