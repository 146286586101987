@charset "UTF-8";
.Menu {
  display: flex;
  justify-content: space-around;
  @include menu-font;
  @include mq-sp{
    flex-direction: column;
  }
  &__item {
    display: inline-block;
    padding: 10px 15px;
  }
  &__sub {
    flex-direction: column;
  }
}
.SpMenu {
  @include mq-sp {
    position: fixed;
    top: $sp-space-unit;
    right: 0;
    left: 0;
    z-index: -1;
    opacity: 0;
    transition: opacify 0s 0.3s ease;
    .isOpened & {
      opacity: 1;
      z-index: opacify 0s 0s ease;
    }
  }
  &__wrapper {
    left: 0;
    width: 100%;
    transition: opacify 0s 0.3s ease;
    &.isOpened {
      position: fixed;
      left: $sp-menu-width;
      box-shadow: 0 1px 10px rgba(black, 0.3)
    }
  }
  &__overlay {
    position: relative;
    display: none;
    .isOpened & {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: $sp-menu-width;
      z-index: 2;
      display: block;
    }
  }
}
