@charset "UTF-8";

.Documents {
  width: 80%;
  margin: auto;
  &__item {
    margin-bottom: 5px;
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
    }
  }
  &__sub {
    padding: $space-unit;
    font-size: 14px;
    @include mq-sp {
      font-size: 12px;
    }
  }
}
