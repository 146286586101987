@charset "UTF-8";

.l-column {
  flex: 0 1 $base-content-width;
  max-width: $base-content-width;
  margin: 0 auto;
  padding: 0 $space-unit;
  @include mq-sp {
    padding: 0 $space-unit*2;
    width: auto;
  }
}
.l-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto;
}
.l-halfColumn {
  flex: 1 0 0px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  @include mq-sp {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
}
.l-halfTile {
  display: flex;
  flex: 1 1 50%;
  margin: 0 auto;
  padding: 0;
  @include mq-sp {
    flex-wrap: wrap;
    flex-basis: 100%;
  }
}
.l-threeTile {
  display: flex;
  flex: 1 2 33%;
  margin: 0;
  padding: 0;
  @include mq-sp {
    flex-basis: 100%;
  }
}
.l-quaterColumn {
  flex: 1 0 0px;
  max-width: ($base-content-width - $column-space*3)/4;
  margin-right: $column-space;
  &:nth-child(4n) {
    margin-right: 0;
  }
}
.l-tripartitionColumn {
  flex: 1 0 0px;
  max-width: ($base-content-width - $column-space*(2/3))/3;
  margin-right: $column-space*2/3;
  width: ($base-content-width - $column-space*6)/3;
  @include mq-sp {
    flex: 1 1 100%;
    max-width: none;
    margin: 0;
  }
  &:last-child {
    margin-right: 0;
  }
}
