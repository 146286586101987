@charset "UTF-8";

.Header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  height: 98%;
  &__head {
    width: 98%;
    flex: 1 1 auto;
    text-align: center;
    &__border{
      width: 80%;
      border-color: $color-border;
      border-top-width: 10px;
      text-align: center;
      margin: auto;
    }
  }
  &__body {

    flex: 3 1 auto;
  }
  //スマートフォン用ハンバーガーボタン
  &__button {
    @include mq-pc {
      display: none;
    }
    @include mq-sp {
      display: block;
      color: $color-main-text;
      font-size: 22px;
    }
  }
}
