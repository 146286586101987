@charset "UTF-8";
//カルーセル
.ImageSlide {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  //画像のラッパー
  &__main {
    display: flex;
    flex-wrap: nowrap;
  }
  //画像１つ分
  &__item {
    flex: 0 0 80%;
  }
  //画像本体
  &__image {
    width: 100%;
    height: auto;
  }
  &__sub {
    position: relative;
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
  &__dot {
    display: block;
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    cursor: pointer;
    width: 16px;
    height: 16px;
    border: 1px solid $color-black;
    border-radius: 8px;
    margin-right: 18px;
    &:last-child {
      margin-right: 0;
    }
    &.isActive {
      background: $color-black;
    }
    @include mq-sp {
      width: 8px;
      height: 8px;
      margin-right: 8px;
    }
  }
}
