@charset "UTF-8";
//fonts
@mixin title-font() {
  font-family: $font-sub;
  font-weight: 400;
}
@mixin menu-font() {
  font-family: $font-sub;
  font-weight: 700;
}
//Media Queries
@mixin mq-sp ($max: $sp-max-width) {
  @media only screen and (max-width: #{$max}) {
    @content;
  }
}
@mixin mq-pc ($min: $pc-min-width) {
  @media only screen and (min-width: #{$min}) {
    @content;
  }
}
