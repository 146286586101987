@charset "UTF-8";

.Footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: $space-unit*2 auto 0;
  padding: $space-unit 0;
  background-color: $color-black;
  color: #eae1cf;
  font-size: 14px;
  @include menu-font;
  &__head {
    flex: 1 2 25%;
  }
  &__head,
  &__body {
    padding-right: $space-unit;
    @include mq-sp{
      margin-bottom: $space-unit;
    }
  }
  &__body,
  &__foot {
    align-self: flex-start;
    flex: 1 1 25%;
    @include mq-sp{
      flex-basis: 100%;
    }
  }
  a {
    color: #eae1cf;
    &:hover {
      color: $color-link;
    }
  }
}
