@charset "UTF-8";

.button {
  display: inline-block;
  padding: 7px 12px;
  color: $color-reverse-line;
  background-color: $color-reverse-fill;
  font-weight: normal;
  font-size: 14px;
  line-height: 1;
}
